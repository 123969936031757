*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  padding: 5px 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
}

.legend {
  display: flex;
  flex-direction: row;
}

.legend-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px 10px 10px;
}

.legend-chip {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin-right: 5px;
}

table {
  margin-top: 10px;
  border-collapse: collapse;
  line-height: 1;
}

tbody,
thead,
tr {
  max-width: 100%;
}

tbody th,
thead th:first-child {
  border-right: 1px solid #78909c;
}

thead th {
  border-bottom: 1px solid #78909c;
}

th {
  text-align: start;
  font-weight: 400;
}

thead th {
  padding-bottom: 5px;
}

tbody th {
  padding-right: 5px;
}

td,
tbody th {
  padding-top: 5px;
}

tbody td,
thead th {
  padding-left: 5px;
  padding-right: 5px;
}

button.icon-button {
  border: 1px solid #c8d7df;
  border-radius: 3px;
  line-height: 1;
  padding: 5px 8px;
  background: linear-gradient(#fff, #e8eff2);
  margin: 0;
}

button.icon-button:hover {
  background: linear-gradient(#c8d7df, #a7c0cd);
  cursor: pointer;
}
