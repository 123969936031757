.metricSelector input {
  margin: 0;
  margin-right: 5px;
}

.metricSelector {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.metricSelector label {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.metricSelector label:not(:first-child) {
  margin-left: 10px;
}

.container {
  font-family: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono",
    "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
    "Fira Mono", "Droid Sans Mono", "Courier New", monospace;
  white-space: pre;
  font-size: 1.2rem;
}

.cell {
  line-height: 0.55;
}