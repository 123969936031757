:root {
  --dark-grey: #4b636e;
}

.grid {
  display: grid;
  grid-template-columns: 15ch 9ch 40ch 10ch repeat(3, 6.5ch) 10ch;
  gap: 1px;

  --cell-padding-horiz: 8px;
  --cell-padding-vert: 5px;
  --light-grey: #a7c0cd;
  --lightest-grey: #e8eff2;
  --darkest-grey: #222c31;
}

.header {
  padding: var(--cell-padding-vert) var(--cell-padding-horiz);
  outline: 1px solid var(--light-grey);
  background-color: var(--lightest-grey);
  font-weight: 600;
  font-size: 0.9rem;
  position: relative;
}

.tooltip {
  display: block;
  background: var(--darkest-grey);
  border: 1px solid var(--darkest-grey);
  color: white;
  border-radius: 6px;
  font-size: 14px;
  z-index: 100;
  top: 0;
  left: 0;
  padding: 4px 6px;
}

.arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent var(--darkest-grey) transparent;
  z-index: 10;
}

.grid input {
  margin: 0;
  border: 0;
  border-radius: 0;
  font-size: 1rem;
  padding: var(--cell-padding-vert) var(--cell-padding-horiz);
}

.grid > input {
  outline: 1px solid var(--light-grey);
}

.grid > input:focus {
  outline-width: 2px;
  outline-color: Highlight;
  outline-color: -webkit-focus-ring-color;
  z-index: 2;
}

.actions,
.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footer {
  grid-column: 1;
  margin-top: 4px;
  margin-left: -1px; /* Compensate for cell outline */
  margin-bottom: 5px;
}

.actions button {
  margin-left: 5px;
}
